import { Name, Option } from '../Requests/SortBy.styles';
import { Dropdown } from '../common/Dropdown';
import React from 'react';
import { TranslatePropsType } from 'common/common.types';
import { withTranslation } from 'react-i18next';
import { LinkOption } from './ExportTo.styles';
import { DropdownState } from '../common/Dropdown/Dropdown';
import { trackEvent } from 'utils/analytics';

interface ExportToPropsType extends TranslatePropsType {
  isExporting: boolean;
  toCsvPath: string;
  toPdf: () => void;
}

const ExportTo = ({ isExporting, toCsvPath, toPdf, t }: ExportToPropsType) => (
  <Dropdown
    color="transparent"
    isLoading={isExporting}
    size="medium"
    text={t(isExporting ? 'exporting' : 'export')}
  >
    {({ close }: DropdownState) => {
      const closeToPdf = () => {
        toPdf();
        close();
      };
      return (
        <>
          <Option
            onClick={() => {
              location.href = 'https://support.securityscorecard.com/hc/en-us/articles/28676218272027-Generating-ATLAS-Report-is-unavailable'
            }}
          >
            <Name data-test="export-to-pdf">
              {t('export', { context: 'pdf' }) as string}
            </Name>
          </Option>
          <LinkOption
            onClick={() => {
              trackEvent('Insights', 'Export CSV');
              close();
            }}
            href={toCsvPath}
            download
          >
            <Name data-test="export-to-csv">
              {t('export', { context: 'csv' }) as string}
            </Name>
          </LinkOption>
        </>
      );
    }}
  </Dropdown>
);

export default withTranslation('Insights')(ExportTo);
